import {gql} from "@apollo/client";

export const ADD_ENTRIES_SETTING = gql`
  mutation addEntriesSetting($inputs: InputEntriesSetting) {
    addEntriesSetting(inputs: $inputs) {
      data {
        _id
        premium
        entryId
        isHide
        isApp
        createdAt
        updatedAt
      }
      error
      ok
    }
  }
`;
